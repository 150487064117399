import type {
   GetServerSidePropsContext,
   GetServerSideProps,
   NextPage,
} from 'next';
import Head from 'next/head';
import Link from 'next/link';

import { useRouter } from 'next/router';

import React from 'react';

import { withSessionSsr } from '../utils/withSession';

import styles from '../styles/Form.module.scss';
import ErrorDialog from '../components/ErrorDialog';

const Login: NextPage = () => {
   const router = useRouter();
   const [email, setEmail] = React.useState('');
   const [password, setPassword] = React.useState('');

   const [error, setError] = React.useState('');
   const [showError, setShowError] = React.useState(false);

   function onSubmit(e: React.FormEvent<HTMLFormElement>) {
      e.preventDefault();
      fetch('/api/login', {
         method: 'POST',
         headers: {
            'Content-Type': 'application/json',
         },
         body: JSON.stringify({
            email,
            password,
         }),
      })
         .then((res) => res.json())
         .then((json) => {
            if (json.success) {
               router.push('/');
            } else {
               setError(json.message);
               setShowError(true);
            }
         });
   }

   return (
      <div className={styles.container}>
         <Head>
            <title>Login</title>
            <meta name='description' content='Generated by create next app' />
            <link rel='icon' href='/favicon.ico' />
         </Head>
         <form className={styles.form} onSubmit={onSubmit}>
            <h2>Login</h2>
            <div className={styles.group}>
               <input
                  id='email'
                  type='email'
                  placeholder='user@domain.com'
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className={styles.input}
               />
               <label htmlFor='email' className={styles.label}>
                  Email Address
               </label>
            </div>
            <div className={styles.group}>
               <input
                  id='password'
                  type='password'
                  placeholder='Password'
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className={styles.input}
               />
               <label htmlFor='password' className={styles.label}>
                  Password
               </label>
            </div>
            <button type='submit' className={styles.button}>
               Login
            </button>
            <div className={styles.altlinks}>
               <Link href='/signup'>
                  <a>Don&#39;t have an account? Sign Up.</a>
               </Link>
               <Link href='/forgot-password'>
                  <a>Forgot your password?</a>
               </Link>
            </div>
         </form>
         <ErrorDialog
            error={error}
            shown={showError}
            close={() => setShowError(false)}
         />
      </div>
   );
};

export const getServerSideProps: GetServerSideProps = withSessionSsr(
   async (context: GetServerSidePropsContext) => {
      if (context.req.session.user) {
         return {
            redirect: {
               destination: '/',
               permanent: false,
            },
         };
      }
      return {
         props: {},
      };
   }
);

export default Login;
